<template>
  <div class="m-auto h-screen flex justify-center items-center">
    <div class="w-1/5 rounded rounded-lg overflow-hidden bg-white shadow shadow-md">
      <div class="bg-black text-white p-5 font-bold">
        <h2>Login</h2>
      </div>
      <div class="p-6">
        <Textbox label="E-mail" v-model="user.email" class="mt-3"/>
        <Textbox label="Password" type="password" v-model="user.password" class="mt-6" v-bind:class="{'mb-6': (errorMessage === '') }"/>

        <HttpPost :url="url('/authenticate')" :body="user" @fetch="fetch => handleFetch(fetch)">
          <span class="text-red text-xs font-bold py-3" v-if="errorMessage !== ''">{{ errorMessage }}</span>
          <Button label="Login" :loading="isLoading" />
        </HttpPost>
      </div>
    </div>
  </div>
</template>
<script>
import {Vue, Component} from 'vue-property-decorator'
import Textbox from "@/mave-ui/components/form/Textbox";
import Button from "@/mave-ui/components/form/Button";
import Card from '@/mave-ui/components/Card.vue'
import HttpPost from "@/mave-ui/components/logic/HttpPost";
import {Functions} from "@/mixins/Functions";

@Component({
  components: {HttpPost, Textbox, Card, Button},
  mixins: [Functions]
})
export default class Login extends Vue {
  isLoading = false;
  errorMessage = '';
  changes = 0;
  user = {
    email: 'example@mave.work',
    password: ''
  }

  handleFetch(fetch) {
    this.isLoading = fetch.isFetching
    this.changes++

    if (fetch.data === null) {
      return;
    }

    this.errorMessage = fetch.data.message;

    if (fetch.data.status === 200) {
      window.location.href = '/'
    }

  }
}
</script>
